/* eslint-disable no-use-before-define */
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
    Action,
    Actions,
    createSelector,
    ofAction,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';
import {
    equals,
} from 'ramda';
import {
    EMPTY,
    map,
    Observable,
    takeUntil,
    tap,
} from 'rxjs';

import {
    MY_PORTFOLIO_UNIVERSE_ID,
    PORTFOLIOS_SEARCH_FIELDS,
} from '~/app/core/constants/portfolios.constants';
import { SHARES_SEARCH_FIELDS } from '~/app/core/constants/shares.constants';
import {
    ACTIVE_RISKS_FOR_SIMULATION,
    RISK_INDICATORS_FOR_SIMULATION,
    SIMULATION_HISTORICAL_VIEW_MODES_DEFAULT_VALUES,
} from '~/app/core/constants/simulation-historical.constants';
import { ComparisonListsService } from '~/app/core/services/api/comparison-lists/comparison-lists.service';
import { PortfoliosService } from '~/app/core/services/api/portfolios/portfolios.service';
import { SharesService } from '~/app/core/services/api/shares/shares.service';
import { SimulationsService } from '~/app/core/services/api/simulations/simulations.service';
import {
    GetAuthorisationAction,
} from '~/app/core/state/authorisation/authorisation.action';
import { ColorUtilsService } from '~/app/core/utils/color-utils/color-utils.service';
import { EntityType } from '~/app/shared/enums/entity-type.enum';
import { Period } from '~/app/shared/enums/period.enum';
import { SimulationHistoricalPeriodType } from '~/app/shared/enums/simulation-historical-period-type.enum';
import { SimulationHistoricalViewModeValue } from '~/app/shared/enums/simulation-historical-view-mode-value.enum';
import { ViewModeType } from '~/app/shared/enums/view-mode-type.enum';
import { ComparedEntity } from '~/app/shared/types/compared-entity.type';
import { OperationSimulationUpdatingPortfolio } from '~/app/shared/types/operations/operation-simulation-updating-portfolio.type';
import { Portfolio } from '~/app/shared/types/portfolio/portfolio.type';
import { ShareCategory } from '~/app/shared/types/shares/share-category.type';
import { Share } from '~/app/shared/types/shares/share.type';
import {
    SimulationCommonBenchmarkParam,
} from '~/app/shared/types/simulation-common/simulation-common-benchmark-param.type';
import { SimulationHistoricalCorrelationValue } from '~/app/shared/types/simulation-historical/simulation-historical-correlation-value.type';
import { SimulationHistoricalParams } from '~/app/shared/types/simulation-historical/simulation-historical-params.type';
import { SimulationHistoricalPeriodParam } from '~/app/shared/types/simulation-historical/simulation-historical-period-param.type';
import { SimulationHistoricalRequest } from '~/app/shared/types/simulation-historical/simulation-historical-request.type';
import { SimulationHistoricalResponse } from '~/app/shared/types/simulation-historical/simulation-historical-response.type';
import { getDate } from '~/app/shared/utils/date/date.utils';

import {
    AddAllocationsToComparedEntitiesAction,
    AddCategoriesToComparedEntitiesAction,
    AddComparisonListToComparedEntitiesAction,
    AddCreatingPortfolioToComparedEntitiesAction,
    AddPortfoliosToComparedEntitiesAction,
    AddSharesToComparedEntitiesAction,
    CancelLaunchSimulationAction,
    ChangeViewModeAction,
    CopyActiveInDraftAction,
    DeleteAllComparedEntitiesAction,
    DeleteComparedEntityAction,
    LaunchSimulationAction,
    ResetAction,
    ResetViewModeDisplayAction,
    SetViewModeDisplayValuesAction,
    ToggleBenchmarkAction,
    UpdateDraftPeriodParamsAction,
} from './simulation-historical.actions';

export type SimulationHistoricalStateModel = {
    draft: SimulationHistoricalParams,
    active: SimulationHistoricalParams,
    simulation: SimulationHistoricalResponse | null,
    viewModeDisplay: {
        [key: string]: SimulationHistoricalViewModeValue[],
    },
    viewMode: ViewModeType,
    requestId: string | null,
}
const defaults = {
    draft: {
        comparedEntities: [],
        period: {
            type: SimulationHistoricalPeriodType.VALUE,
            range: null,
            value: Period.P01Y,
        },
        benchmark: null,
    },
    active: {
        comparedEntities: [],
        period: {
            type: null,
            range: null,
            value: null,
        },
        benchmark: null,
    },
    simulation: null,
    viewModeDisplay: {
        PRO: SIMULATION_HISTORICAL_VIEW_MODES_DEFAULT_VALUES.PRO,
        MAIN: SIMULATION_HISTORICAL_VIEW_MODES_DEFAULT_VALUES.MAIN,
        ADVANCED: SIMULATION_HISTORICAL_VIEW_MODES_DEFAULT_VALUES.ADVANCED,
        SHARED: [],
    },
    viewMode: ViewModeType.MAIN,
    requestId: null,
};

@State<SimulationHistoricalStateModel>({
    name: 'simulationHistorical',
    defaults,
})
@Injectable()
export class SimulationHistoricalState {
    constructor(
        public sharesService: SharesService,
        public portfoliosService: PortfoliosService,
        public simulationsService: SimulationsService,
        private comparisonListsService: ComparisonListsService,
        private colorsUtilsService: ColorUtilsService,
        private transloco: TranslocoService,
        private actions$: Actions,
    ) {
    }

    static isDisplayedInCurrentViewMode(viewModeValue: SimulationHistoricalViewModeValue) {
        // eslint-disable-next-line max-len
        return createSelector([SimulationHistoricalState], (state: SimulationHistoricalStateModel) => state.viewModeDisplay[state.viewMode].some((item) => item === viewModeValue));
    }

    @Selector()
    static getDraftPeriodParams(state: SimulationHistoricalStateModel): SimulationHistoricalPeriodParam {
        return state.draft.period;
    }

    @Selector()
    static getDraftComparedAllocationCount(state: SimulationHistoricalStateModel): number {
        return state.draft.comparedEntities.filter((item) => item.type === EntityType.PORTFOLIO || item.type === EntityType.ALLOCATION)?.length ?? 0;
    }

    @Selector()
    static getDraftComparedEntities(state: SimulationHistoricalStateModel): ComparedEntity<Share | Portfolio | ShareCategory>[] {
        const entityBenchmark = state.draft.comparedEntities
            .find((comparedEntity) => comparedEntity.id === state.draft.benchmark?.id && comparedEntity.type === state.draft.benchmark?.type);
        return [
            ...(entityBenchmark ? [entityBenchmark] : []),
            ...state.draft.comparedEntities.reduce((acc: ComparedEntity<Share | Portfolio | ShareCategory>[], comparedEntity) => {
                if (comparedEntity.id !== entityBenchmark?.id || comparedEntity.type !== entityBenchmark.type) {
                    acc.push({
                        ...comparedEntity,
                    });
                }
                return acc;
            }, []),
        ];
    }

    @Selector()
    static getDraftComparedEntitiesWithoutData(state: SimulationHistoricalStateModel): ComparedEntity[] {
        return state.draft.comparedEntities;
    }

    @Selector()
    static getDraftBenchmark(state: SimulationHistoricalStateModel): SimulationCommonBenchmarkParam {
        return state.draft.benchmark;
    }

    @Selector()
    static getDraftComparedEntitiesCount(state: SimulationHistoricalStateModel): number {
        return state.draft.comparedEntities.length;
    }

    @Selector()
    static getDraftComparedPortfoliosEntitiesCount(state: SimulationHistoricalStateModel): number {
        return state.draft.comparedEntities.filter((item) => item.type === EntityType.PORTFOLIO).length;
    }

    @Selector()
    static getActiveComparedEntities(state: SimulationHistoricalStateModel): ComparedEntity<Share | Portfolio | ShareCategory>[] {
        return [
            ...state.active.comparedEntities.map((comparedEntity) => ({
                ...comparedEntity,
                isReference: state.active.benchmark?.id === comparedEntity.id && state.active.benchmark.type === comparedEntity.type,
            })),
        ];
    }

    @Selector()
    static getActivePeriodParams(state: SimulationHistoricalStateModel): SimulationHistoricalPeriodParam {
        return state.active.period;
    }

    @Selector()
    static getActiveBenchmark(state: SimulationHistoricalStateModel): SimulationCommonBenchmarkParam {
        return state.active.benchmark;
    }

    @Selector()
    static getActiveComparedEntitiesWithoutData(state: SimulationHistoricalStateModel): ComparedEntity[] {
        return state.active.comparedEntities;
    }

    @Selector()
    static getActivePortfoliosWithoutData(state: SimulationHistoricalStateModel): ComparedEntity[] {
        return state.active.comparedEntities.filter((item) => item.type === EntityType.PORTFOLIO);
    }

    @Selector()
    static getActiveAllocationsWithoutData(state: SimulationHistoricalStateModel): ComparedEntity[] {
        return state.active.comparedEntities.filter((item) => item.type === EntityType.ALLOCATION);
    }

    @Selector()
    static getActivePortfolios(state: SimulationHistoricalStateModel): ComparedEntity<Portfolio>[] {
        return state.active.comparedEntities
            .reduce((acc: ComparedEntity<Portfolio>[], comparedEntity) => {
                if (comparedEntity.type === EntityType.PORTFOLIO) {
                    acc.push({
                        ...comparedEntity,
                    });
                }
                return acc;
            }, []);
    }

    @Selector()
    static getActiveAllocations(state: SimulationHistoricalStateModel): ComparedEntity<Portfolio, OperationSimulationUpdatingPortfolio>[] {
        return state.active.comparedEntities
            .reduce((acc: ComparedEntity<Portfolio, OperationSimulationUpdatingPortfolio>[], comparedEntity) => {
                if (comparedEntity.type === EntityType.ALLOCATION) {
                    acc.push(comparedEntity);
                }
                return acc;
            }, []);
    }

    @Selector()
    static getActiveComparedEntitiesCount(state: SimulationHistoricalStateModel): number {
        return state.active.comparedEntities.length;
    }

    @Selector()
    static getRequestId(state: SimulationHistoricalStateModel): string | null {
        return state.requestId;
    }

    @Selector()
    static getSimulation(state: SimulationHistoricalStateModel): SimulationHistoricalResponse | null {
        return state.simulation;
    }

    @Selector()
    static getViewModeDisplay(state: SimulationHistoricalStateModel) {
        return state.viewModeDisplay[state.viewMode];
    }

    @Selector()
    static getViewModeParam(state: SimulationHistoricalStateModel): ViewModeType {
        return state.viewMode;
    }

    @Action(ResetAction)
    public reset({ setState, getState }: StateContext<SimulationHistoricalStateModel>) {
        const state = getState();
        setState({
            ...defaults,
            viewMode: state.viewMode ? state.viewMode : defaults.viewMode,
            draft: {
                ...defaults.draft,
                period: state.draft.period?.value || state.draft.period?.range ? state.draft.period : defaults.draft.period,
            },
        });
    }

    @Action(AddComparisonListToComparedEntitiesAction)
    public addComparisonListToComparedEntities({ dispatch }: StateContext<SimulationHistoricalStateModel>, action: AddComparisonListToComparedEntitiesAction) {
        return this.comparisonListsService.getComparisonListEntities(action.listId)
            .pipe(
                tap((items) => {
                    const shares = items.filter((item) => item.type === 'share');
                    const portfolios = items.filter((item) => item.type === 'portfolio');
                    const categories = items.filter((item) => item.type === 'category');

                    if (shares.length > 0) {
                        dispatch(new AddSharesToComparedEntitiesAction([
                            ...shares.map((share) => ({ id: share.id })),
                        ]));
                    }

                    if (portfolios.length > 0) {
                        dispatch(new AddPortfoliosToComparedEntitiesAction([
                            ...portfolios.map((portfolio) => ({ id: portfolio.id })),
                        ]));
                    }

                    if (categories.length > 0) {
                        dispatch(new AddCategoriesToComparedEntitiesAction([
                            ...categories.map((category) => category.id),
                        ]));
                    }
                }),
            );
    }

    @Action(AddSharesToComparedEntitiesAction)
    public addSharesToComparedEntities({ getState, patchState }: StateContext<SimulationHistoricalStateModel>, action: AddSharesToComparedEntitiesAction) {
        return this.getSharesData(action.shares.map((share) => share.id)).pipe(
            tap((shares) => {
                const state = getState();

                const draftComparedSharesEntitiesIds = state.draft.comparedEntities
                    .filter((item) => item.type === EntityType.SHARE)
                    .map((item) => item.id);

                let comparedEntities = [
                    ...state.draft.comparedEntities,
                    ...shares
                        .filter((share) => !draftComparedSharesEntitiesIds.includes(share.id))
                        .map((share) => {
                            const shareParam = action.shares.find((sha) => sha.id === share.id);
                            return {
                                id: share.id,
                                name: share.name,
                                isReference: false,
                                type: EntityType.SHARE,
                                data: {
                                    ...share,
                                    ...(shareParam?.srri
                                        ? { srri: shareParam?.srri }
                                        : {}),
                                },
                            };
                        }),
                ];

                const colors = this.colorsUtilsService.getThemeColors(comparedEntities.length);

                comparedEntities = comparedEntities.map((item, index) => ({
                    ...item,
                    color: colors[index],
                }));

                patchState({
                    draft: {
                        benchmark: state.draft.benchmark,
                        comparedEntities,
                        period: state.draft.period,
                    },
                });
            }),
        );
    }

    @Action(AddPortfoliosToComparedEntitiesAction)
    public addPortfoliosToComparedEntities({ getState, patchState }: StateContext<SimulationHistoricalStateModel>,
        action: AddPortfoliosToComparedEntitiesAction) {
        return this.getPortfoliosData(action.portfolios.map((portfolio) => portfolio.id)).pipe(
            tap((portfolios) => {
                const state = getState();

                const draftComparedPortfoliosEntitiesIds = state.draft.comparedEntities
                    .filter((item) => item.type === EntityType.PORTFOLIO)
                    .map((item) => item.id);

                let comparedEntities = [
                    ...state.draft.comparedEntities,
                    ...portfolios
                        .filter((portfolio) => !draftComparedPortfoliosEntitiesIds.includes(portfolio.id))
                        .map((portfolio) => {
                            const portfolioParam = action.portfolios.find((ptf) => ptf.id === portfolio.id);
                            return {
                                id: portfolio.id,
                                name: portfolio.name,
                                isReference: false,
                                type: EntityType.PORTFOLIO,
                                data: {
                                    ...portfolio,
                                    ...(portfolioParam?.srri
                                        ? { srri: portfolioParam?.srri }
                                        : {}),
                                },
                            };
                        }),
                ];

                const colors = this.colorsUtilsService.getThemeColors(comparedEntities.length);

                comparedEntities = comparedEntities.map((item, index) => ({
                    ...item,
                    color: colors[index],
                }));

                patchState({
                    draft: {
                        benchmark: state.draft.benchmark,
                        comparedEntities,
                        period: state.draft.period,
                    },
                });
            }),
        );
    }

    @Action(AddAllocationsToComparedEntitiesAction)
    public addAllocationsToComparedEntities({ getState, patchState }: StateContext<SimulationHistoricalStateModel>,
        action: AddAllocationsToComparedEntitiesAction) {
        const portfolioIds = action.allocations.map((alloc) => Math.abs(alloc.allocation.id));

        return this.getPortfoliosData(portfolioIds).pipe(
            tap((portfolios) => {
                const state = getState();

                const draftComparedAllocationsEntitiesIds = state.draft.comparedEntities
                    .filter((item) => item.type === EntityType.PORTFOLIO || item.type === EntityType.ALLOCATION)
                    .map((item) => item.id);

                let comparedEntities = [
                    ...state.draft.comparedEntities,
                    ...portfolios
                        .filter((portfolio) => !draftComparedAllocationsEntitiesIds.includes(portfolio.id))
                        .map((portfolio) => {
                            const allocation = action.allocations.find((alloc) => alloc.allocation.id === -portfolio.id);
                            return {
                                id: portfolio.id,
                                name: this.transloco.translate('projection.current_portfolio', { name: portfolio.name }),
                                isReference: false,
                                isLock: true,
                                type: EntityType.PORTFOLIO,
                                data: {
                                    ...portfolio,
                                    srri: allocation?.calculatedSrri ?? 1,
                                },
                            };
                        }),
                    ...portfolios
                        .filter((portfolio) => !draftComparedAllocationsEntitiesIds.includes(-portfolio.id))
                        .map((portfolio) => {
                            const allocation = action.allocations.find((alloc) => alloc.allocation.id === -portfolio.id);
                            return {
                                id: -portfolio.id,
                                name: this.transloco.translate('projection.updating_portfolio', { name: portfolio.name }),
                                isReference: false,
                                isLock: true,
                                type: EntityType.ALLOCATION,
                                extraData: allocation,
                                data: {
                                    ...portfolio,
                                    srri: allocation?.newCalculatedSrri ?? (allocation?.calculatedSrri ?? 1),
                                },
                            };
                        }),
                ];

                const colors = this.colorsUtilsService.getThemeColors(comparedEntities.length);

                comparedEntities = comparedEntities.map((item, index) => ({
                    ...item,
                    color: colors[index],
                }));

                patchState({
                    draft: {
                        benchmark: state.draft.benchmark,
                        comparedEntities,
                        period: state.draft.period,
                    },
                });
            }),
        );
    }

    @Action(AddCreatingPortfolioToComparedEntitiesAction)
    public addCreatingPortfolioToComparedEntities({ getState, patchState }: StateContext<SimulationHistoricalStateModel>,
        action: AddCreatingPortfolioToComparedEntitiesAction) {
        const state = getState();

        let comparedEntities = [
            ...state.draft.comparedEntities,
            ...(action.initialAllocation ? [{
                id: -1,
                name: this.transloco.translate('projection.initial_new_portfolio', { name: action.portfolio.name }),
                isReference: false,
                isLock: true,
                type: EntityType.ALLOCATION,
                extraData: {
                    allocation: {
                        id: -1,
                        type: EntityType.ALLOCATION,
                        allocation: {
                            shares: action.initialAllocation.shares,
                            cashes: action.initialAllocation.cashes,
                        },
                        currency: action.initialAllocation.currency,
                    },
                    calculatedSrri: action.portfolio.calculatedSrri ?? 1,
                },
                data: {
                    ...action.portfolio,
                    srri: action.portfolio.calculatedSrri,
                },
            }] : []),
            {
                id: 0,
                name: this.transloco.translate('projection.new_portfolio', { name: action.portfolio.name }),
                isReference: false,
                isLock: true,
                type: EntityType.ALLOCATION,
                extraData: {
                    allocation: action.allocation,
                    calculatedSrri: action.portfolio.calculatedSrri ?? 1,
                },
                data: {
                    ...action.portfolio,
                    srri: action.portfolio.calculatedSrri,
                },
            },
        ];

        const colors = this.colorsUtilsService.getThemeColors(comparedEntities.length);

        comparedEntities = comparedEntities.map((item, index) => ({
            ...item,
            color: colors[index],
        }));

        patchState({
            draft: {
                benchmark: state.draft.benchmark,
                comparedEntities,
                period: state.draft.period,
            },
        });
    }

    @Action(AddCategoriesToComparedEntitiesAction)
    public addCategoriesToComparedEntities(
        { getState, patchState }: StateContext<SimulationHistoricalStateModel>,
        action: AddCategoriesToComparedEntitiesAction,
    ) {
        return this.getCategoriesData(action.categoryIds).pipe(
            tap((categories) => {
                const state = getState();

                const draftComparedCategoriesEntitiesIds = state.draft.comparedEntities
                    .filter((item) => item.type === EntityType.CATEGORY)
                    .map((item) => item.id);

                let comparedEntities = [
                    ...state.draft.comparedEntities,
                    ...categories
                        .filter((category) => !draftComparedCategoriesEntitiesIds.includes(category.id))
                        .map((category) => ({
                            id: category.id,
                            name: category.name,
                            isReference: false,
                            type: EntityType.CATEGORY,
                            data: category,
                        })),
                ];

                const colors = this.colorsUtilsService.getThemeColors(comparedEntities.length);

                comparedEntities = comparedEntities.map((item, index) => ({
                    ...item,
                    color: colors[index],
                }));

                patchState({
                    draft: {
                        benchmark: state.draft.benchmark,
                        comparedEntities,
                        period: state.draft.period,
                    },
                });
            }),
        );
    }

    @Action(DeleteComparedEntityAction)
    public deleteComparedEntity({ getState, patchState }: StateContext<SimulationHistoricalStateModel>, action: DeleteComparedEntityAction) {
        const state = getState();
        patchState({
            draft: {
                ...state.draft,
                comparedEntities: state.draft.comparedEntities.filter((entity) => entity.id !== action.entityId || entity.type !== action.entityType),
            },
        });
    }

    @Action(DeleteAllComparedEntitiesAction)
    public deleteAllComparedEntities({ getState, patchState }: StateContext<SimulationHistoricalStateModel>) {
        const state = getState();

        patchState({
            draft: {
                ...state.draft,
                comparedEntities: state.draft.comparedEntities.reduce((acc: ComparedEntity[], comparedEntity) => {
                    if (comparedEntity.isLock) {
                        acc.push(comparedEntity);
                    }
                    return acc;
                }, []),
            },
        });
    }

    @Action(UpdateDraftPeriodParamsAction)
    updateDraftPeriodParams({ getState, patchState }: StateContext<SimulationHistoricalStateModel>, action: UpdateDraftPeriodParamsAction) {
        const state = getState();
        patchState({
            draft: {
                ...state.draft,
                period: {
                    type: action.type,
                    range: action.range,
                    value: action.value,
                },
            },
        });
    }

    @Action(ToggleBenchmarkAction)
    public toggleBenchmark({ getState, patchState }: StateContext<SimulationHistoricalStateModel>, action: ToggleBenchmarkAction) {
        const state = getState();
        if (!action.benchmark || (state.draft.benchmark?.id === action.benchmark.id && state.draft.benchmark?.type === action.benchmark.type)) {
            patchState({
                draft: {
                    ...state.draft,
                    benchmark: null,
                },
            });
        } else {
            patchState({
                draft: {
                    ...state.draft,
                    benchmark: action.benchmark,
                },
            });
        }
    }

    @Action(LaunchSimulationAction, { cancelUncompleted: true })
    public launchSimulation({ getState, patchState, dispatch }: StateContext<SimulationHistoricalStateModel>) {
        const state = getState();

        const simulationParams = {
            startDate: (state.draft.period.type === SimulationHistoricalPeriodType.RANGE && state.draft.period.range?.start)
                ? getDate(state.draft.period.range?.start) : null,
            endDate: (state.draft.period.type === SimulationHistoricalPeriodType.RANGE && state.draft.period.range?.end)
                ? getDate(state.draft.period.range?.end) : null,
            period: state.draft.period.type === SimulationHistoricalPeriodType.VALUE ? state.draft.period.value : null,
            requestId: state.requestId,
            benchmark: state.draft.benchmark ? {
                id: state.draft.benchmark.id,
                type: state.draft.benchmark.type,
                ...(state.draft.benchmark.type === EntityType.ALLOCATION ? {
                    currency: state.draft.comparedEntities.find((entity) => entity.id === state.draft.benchmark?.id
                        && entity.type === state.draft.benchmark.type)?.extraData?.allocation.currency,
                    allocation: state.draft.comparedEntities.find((entity) => entity.id === state.draft.benchmark?.id
                        && entity.type === state.draft.benchmark.type)?.extraData?.allocation.allocation,
                } : {}),
            } : null,
            references: state.draft.comparedEntities
                .filter((entity) => (state.draft.benchmark ? entity.id !== state.draft.benchmark.id || entity.type !== state.draft.benchmark.type : true))
                .map((entity) => ({
                    id: entity.id,
                    type: entity.type,
                    ...(entity.type === EntityType.ALLOCATION ? {
                        currency: entity.extraData?.allocation.currency,
                        allocation: entity.extraData?.allocation.allocation,
                    } : {}),
                })),
            riskIndicators: RISK_INDICATORS_FOR_SIMULATION,
            activeRisks: state.draft.benchmark ? ACTIVE_RISKS_FOR_SIMULATION : [],
        };

        const simulationHaveToBeLaunched = this.haveToBeLaunched(state, patchState);

        if (!simulationHaveToBeLaunched) return EMPTY;
        return this.simulationsService.getHistoricalSimulation(simulationParams as unknown as SimulationHistoricalRequest)
            .pipe(
                tap(([simulationResult, requestId]: [SimulationHistoricalResponse, string]) => {
                    patchState({
                        active: {
                            ...state.draft,
                        },
                        simulation: simulationResult,
                        requestId,
                    });
                }),
                tap(() => {
                    dispatch(new GetAuthorisationAction(true));
                }),
                takeUntil(this.actions$.pipe(ofAction(CancelLaunchSimulationAction))),
            );
    }

    @Action(CopyActiveInDraftAction)
    public copyActiveInDraft({ getState, patchState }: StateContext<SimulationHistoricalStateModel>) {
        const state = getState();
        patchState({
            draft: {
                ...state.active,
            },
        });
    }

    @Action(ChangeViewModeAction)
    changeViewMode({ patchState }: StateContext<SimulationHistoricalStateModel>, action: ChangeViewModeAction) {
        patchState({
            viewMode: action.viewMode,
        });
    }

    @Action(ResetViewModeDisplayAction)
    resetViewModeDisplay({ getState, patchState }: StateContext<SimulationHistoricalStateModel>) {
        const state = getState();

        const currentViewMode = state.viewMode;

        patchState({
            viewModeDisplay: {
                ...state.viewModeDisplay,
                ...(
                    currentViewMode === ViewModeType.PRO
                        ? {
                            PRO: SIMULATION_HISTORICAL_VIEW_MODES_DEFAULT_VALUES.PRO,
                        }
                        : {}
                ),
                ...(
                    currentViewMode === ViewModeType.ADVANCED
                        ? {
                            ADVANCED: SIMULATION_HISTORICAL_VIEW_MODES_DEFAULT_VALUES.ADVANCED,
                        }
                        : {}
                ),
                ...(
                    currentViewMode === ViewModeType.MAIN
                        ? {
                            MAIN: SIMULATION_HISTORICAL_VIEW_MODES_DEFAULT_VALUES.MAIN,
                        }
                        : {}
                ),
            },
        });
    }

    @Action(SetViewModeDisplayValuesAction)
    setViewModeDisplayValuesAction({ getState, patchState }: StateContext<SimulationHistoricalStateModel>, action: SetViewModeDisplayValuesAction) {
        const state = getState();
        patchState({
            viewModeDisplay: {
                ...state.viewModeDisplay,
                [state.viewMode]: action.display,
            },
        });
    }

    private getSharesData(shareIds: number[]) : Observable<Share[]> {
        return this.sharesService.search({
            fields: SHARES_SEARCH_FIELDS.join(','),
            q: '',
            filters: [
                {
                    exclude: false,
                    property: 'id',
                    union: true,
                    values: shareIds.map((id) => id.toString()),
                },
            ],
            sorts: [],
            searchUniverse: 0,
            subSegmentation: 'ALL',
            includeMetadata: false,
            startAt: 0,
            size: 50,
            requestId: '',
        }).pipe(
            map((result) => result.values),
        );
    }

    private getPortfoliosData(portfoliosIds: number[]) : Observable<Portfolio[]> {
        return this.portfoliosService.search({
            fields: `${PORTFOLIOS_SEARCH_FIELDS.join(',')}alerts,calculatedSrri,srri,`,
            q: '',
            filters: [
                {
                    exclude: false,
                    property: 'id',
                    union: true,
                    values: portfoliosIds.map((id) => id.toString()),
                },
            ],
            sorts: [],
            portfolioUniverse: MY_PORTFOLIO_UNIVERSE_ID,
            subSegmentation: 'ALL',
            includeMetadata: false,
            startAt: 0,
            size: 50,
            requestId: '',
        }).pipe(
            map((result) => result.values),
        );
    }

    private getCategoriesData(categoryIds: number[]) : Observable<readonly ShareCategory[]> {
        return this.sharesService.getCategories(categoryIds);
    }

    private haveToBeLaunched(state: SimulationHistoricalStateModel, patchState: (val: Partial<SimulationHistoricalStateModel>)
        => SimulationHistoricalStateModel) {
        if (!state.active || !state.simulation) {
            return true;
        }
        if (!equals(state.draft.period, state.active.period)) {
            return true;
        }
        if (!equals(state.draft.benchmark, state.active.benchmark)) {
            return true;
        }
        const entitiesAdded = state.draft.comparedEntities.reduce((acc: ComparedEntity[], draftEntity) => {
            if (!state.active.comparedEntities.find((activeEntity) => activeEntity.id === draftEntity.id && activeEntity.type === draftEntity.type)) {
                acc.push(draftEntity);
            }
            return acc;
        }, []);
        if (entitiesAdded.length > 0) return true;
        const entitiesDeleted = state.active.comparedEntities.reduce((acc: ComparedEntity[], activeEntity) => {
            if (!state.draft.comparedEntities.find((draftEntity) => activeEntity.id === draftEntity.id && activeEntity.type === draftEntity.type)) {
                acc.push(activeEntity);
            }
            return acc;
        }, []);
        if (entitiesDeleted.length > 0) {
            patchState({
                simulation: {
                    ...state.simulation,
                    references: state.simulation.references
                        .filter((reference) => !entitiesDeleted.find((entity) => entity.id === reference.id && entity.type === reference.type)),
                    ...(state.simulation.correlation ? {
                        correlation: {
                            averageValue: state.simulation.correlation.averageValue,
                            values: state.simulation.correlation.values.reduce((acc: SimulationHistoricalCorrelationValue[], correlationValue) => {
                                if (!entitiesDeleted.find((entity) => entity.id === correlationValue.id && entity.type === correlationValue.type)) {
                                    acc.push({
                                        ...correlationValue,
                                        // @ts-ignore
                                        values: correlationValue.values
                                            .filter((value) => !entitiesDeleted.find((entity) => entity.id === value.id && entity.type === value.type)),
                                    });
                                }
                                return acc;
                            }, []),
                        },
                    } : {}),
                },
                active: {
                    ...state.draft,
                },
            });
        }
        return false;
    }
}
